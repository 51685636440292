import React, { Component } from "react"
import { Link } from "gatsby"
import Modal from "react-modal"
import xIcon from "../images/common/x.svg"
import logo from "../images/common/logo.jpg"

export default class NavModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    Modal.setAppElement("body")
  }

  isPartiallyActive({ isPartiallyCurrent }) {
    return isPartiallyCurrent ? { className: "link active-link" } : {}
  }

  render() {
    const customStyles = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1001,
      },
      content: {
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        width: "330px",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        outline: "none",
        padding: "20px",
        backgroundColor: "#fdfdfd",
        overflowY: "scroll",
        overflowX: "hidden",
      },
    }
    const mobileStyles = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1001,
      },
      content: {
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        width: "100%",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        outline: "none",
        padding: "0px 20px 0px 20px",
        backgroundColor: "#fdfdfd",
        boxSizing: "border-box",
        overflowY: "scroll",
        overflowX: "hidden",
      },
    }

    return (
      <Modal
        className="nav-modal"
        isOpen={this.props.modalOpen}
        style={this.props.mobile ? mobileStyles : customStyles}
      >
        <img className="x-icon" src={xIcon} onClick={this.props.toggleModal} />
        <nav>
          <Link className="logo-link" to="/">
            <img className="logo" src={logo} />
          </Link>
          <Link to="/" className="link" activeClassName="active-link">
            Home
          </Link>
          <Link
            to="/about"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            About
          </Link>
          <Link
            to="/books"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            Books
          </Link>
          <Link
            to="/poems"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            Poems
          </Link>
          <Link
            to="/coaching"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            Coaching
          </Link>
          {/* <Link to="/shop" className="link" activeClassName="active-link">
            Shop
          </Link>
          <Link to="/blog" className="link" activeClassName="active-link">
            Blog
          </Link> */}
          <Link
            to="/photography"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            Photos
          </Link>
          <Link
            to="/faq"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            FAQ
          </Link>
          <Link
            to="/contact"
            getProps={this.isPartiallyActive}
            className="link"
            activeClassName="active-link"
          >
            Contact
          </Link>
        </nav>
      </Modal>
    )
  }
}
