import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Nav from "./nav"
import menuIcon from "../images/common/menu.svg"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      width: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    let width = window.innerWidth
    if (width > 850) {
      this.setState({
        width,
        modalOpen: false,
      })
    } else {
      this.setState({ width })
    }
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    return (
      <header>
        <Link to="/" className="link">
          <h1>Gabriella Alziari</h1>
        </Link>
        <img
          className="mobile-menu"
          src={menuIcon}
          onClick={this.toggleModal.bind(this)}
        />
        <Nav
          modalOpen={this.state.modalOpen}
          mobile={this.state.width < 520}
          toggleModal={this.toggleModal.bind(this)}
        />
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
