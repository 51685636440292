import React, { Fragment } from "react"
import { Link } from "gatsby"
import NavModal from "./navModal"

const Nav = props => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "link active-link" } : {}
  }

  return (
    <Fragment>
      <nav className="header-nav">
        <Link to="/" className="link" activeClassName="active-link">
          Home
        </Link>
        <Link
          to="/about/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          About
        </Link>
        <Link
          to="/books/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Books
        </Link>
        <Link
          to="/poems/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Poems
        </Link>
        <Link
          to="/coaching/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Coaching
        </Link>
        {/* <Link to="/shop/" className="link" activeClassName="active-link">
          Shop
        </Link> */}
        {/* <Link to="/blog/" className="link" activeClassName="active-link">
          Blog
        </Link> */}
        <Link
          to="/photography/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Photos
        </Link>
        <Link
          to="/faq/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          FAQ
        </Link>
        <Link
          to="/contact/"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Contact
        </Link>
      </nav>
      <NavModal
        modalOpen={props.modalOpen}
        mobile={props.mobile}
        toggleModal={props.toggleModal}
      />
    </Fragment>
  )
}

export default Nav
