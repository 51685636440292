import React from "react"
import { Link } from "gatsby"
import logo from "../images/common/logo.jpg"

const Footer = () => {
  const currYear = new Date().getFullYear()

  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "link active-link" } : {}
  }

  return (
    <footer>
      <nav className="footer-nav">
        <Link to="/" className="link" activeClassName="active-link">
          Home
        </Link>
        <Link
          to="/about"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          About
        </Link>
        <Link
          to="/books"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Books
        </Link>
        <Link
          to="/poems"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Poems
        </Link>
        <Link
          to="/coaching"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Coaching
        </Link>
        {/* <Link to="/shop" className="link" activeClassName="active-link">
          Shop
        </Link> */}
        {/* <Link to="/blog" className="link" activeClassName="active-link">
          Blog
        </Link> */}
        <Link
          to="/photography"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Photos
        </Link>
        <Link
          to="/faq"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          FAQ
        </Link>
        <Link
          to="/contact"
          getProps={isPartiallyActive}
          className="link"
          activeClassName="active-link"
        >
          Contact
        </Link>
      </nav>
      <div className="footer-details">
        <div className="copyright">
          Copyright © {currYear} Gabriella Alziari, Alziari Coaching LLC
        </div>
        <div className="built-by">
          Built by{" "}
          <a
            href="https://www.teqengine.com"
            target="_blank"
            rel="noopener noreferrer"
            className="built-by-link"
          >
            TeqEngine
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
